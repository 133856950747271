// colours
$yellow: #fbb44a;
$grey: #2b281f;
$light_yellow: #fbecab;
$charcoal: #36454f;

body {
  font-family: "Roboto", sans-serif;
  background: #ffffff;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

#nav {
  width: 250px;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $grey;
  z-index: 2;
}

#bar {
  width: calc(100vw - 250px);
  height: 60px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: $light_yellow;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 3px -3px 10px;
}

#app {
  width: calc(100vw - 250px);
  height: calc(100vh - 60px);
  overflow-y: scroll;
  position: absolute;
  right: 0px;
  top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#page {
  @extend #app;
  top: 0px;
  height: 100vh;
}

#uploadPage {
  @extend #app;
  height: 100vh;
  top: 0px;
}

#authPage {
  @extend #page;
  color: $charcoal;
}

#usernameInput,
#passwordInput,
#nameInput,
#confirmPassword {
  width: 300px;
  border-radius: 5px;
  border: solid 1px $charcoal;
  padding: 12px;
}

#submitBtn {
  width: 100px;
  margin: auto;
  border-radius: 5px;
  border: none;
  background-color: $light_yellow;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}

@media only screen and (min-width: 851px) {
  #app::-webkit-scrollbar {
    width: 10px;
    background-color: none;
  }

  #app::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $yellow;
  }
}

.navTab {
  display: block;
  list-style: none;
  text-decoration: none;
  font-size: 15px;
  color: white;
  margin-bottom: 7px;
  max-width: 200px;
  padding: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.tabActive {
  background: linear-gradient(to right, #efc056, #e27954);
  border-radius: 5px;
  color: $grey;
}

.tabActive:hover {
  opacity: 0.8;
}

.iconInactive {
  font-size: 20px;
  background-color: #f3ec78;
  background-image: linear-gradient(to right, #efc056, #e27954);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-right: 15px;
}

.tabInactive:hover {
  background: grey;
  border-radius: 5px;
}

.iconActive {
  color: #fdfefb;
  font-size: 20px;
  margin-right: 15px;
}

#tint {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: black;
  opacity: 0.2;
  z-index: 1;
}

#logoText {
  width: 210px;
  margin: 10px 10px 20px 20px;
}

.popupNav {
  width: 250px;
  color: white;
  text-align: center;
  border-top: solid #555555 1px;
  font-size: 13px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 345px);

  h3 {
    cursor: pointer;
  }

  span {
    display: block;
    min-height: calc(100vh - 395px);
  }
}

.popupNav::-webkit-scrollbar {
  width: 5px;
  background-color: none;
}

.popupNav::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e9e9e9;
}

.post {
  position: relative;
  color: $charcoal;
  padding-bottom: 10px;
  background: #ffffff;
  font-weight: normal;
  break-inside: avoid-column;
  text-align: center;
  width: 250px;
  box-shadow: 1px 1px 10px 1px;
  border-radius: 10px;
  margin: 40px;
  cursor: default;
}

.postTitle {
  margin: 10px;
}

.postThumbnail {
  width: 250px;
  height: 140.63px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  border-style: none;
}

.postThumbnail:hover {
  opacity: 0.8;
  cursor: pointer;
}

.statDiv {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  color: #36454f90;
}

.statPrice {
  grid-column: 2;
  grid-row: 2;
  margin-left: 50px;
  margin-top: 1px;
}

.statLikes {
  grid-column: 2;
  grid-row: 2;
  margin-right: 20px;
}

.statCategory {
  grid-column: 2;
  grid-row: 1;
}

.statName {
  grid-column: 1;
  grid-row: 1;
  max-width: 80%;
  color: inherit;
}

.addWishlist {
  position: absolute;
  bottom: -10px;
  left: -10px;
  background-color: white;
  border: solid 1px #e9e9e9;
  border-radius: 100px;
  padding: 10px;
  width: 30px;
}

.wishlistOptions {
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: -10px;
  background-color: white;
  border: solid 1px #e9e9e9;
  border-radius: 100px;
  padding: 2px;
  width: 70px;

  i {
    padding: 8px;
    border-radius: 100px;
  }

  i:hover {
    background-color: $yellow;
    cursor: pointer;
  }
}

.addWishlist:hover {
  background-color: $yellow;
  cursor: pointer;
}

#searchBar {
  width: calc(80vw - 500px);
  min-width: 250px;
  position: relative;
  margin: 10px;
  margin-left: 20px;
}

#searchInput {
  border: none;
  border-radius: 10px;
  background-color: white;
  width: calc(80vw - 500px);
  min-width: 250px;
  padding: 10px;
}

.fa-search {
  position: absolute;
  right: -10px;
  top: 0px;
  padding: 10px;
  cursor: pointer;
}

.fa-search:hover {
  color: $yellow;
}

#login {
  color: $grey;
  float: right;
  margin-right: 170px;
  margin-top: -45px;
}

#login:hover {
  color: $yellow;
  cursor: pointer;
}

#register {
  color: $grey;
  float: right;
  margin-right: 30px;
  margin-top: -45px;
}

#register:hover {
  color: $yellow;
  cursor: pointer;
}

.userLoginIcon {
  padding: 12px;
  background-color: white;
  color: $yellow;
  border-radius: 100px;
  margin-right: 10px;
  box-shadow: 3px 3px 5px grey;
}

#welcomeMessage {
  @extend .userLoginIcon;
  padding: 7.5px 15px;
  float: right;
  margin-right: 15px;
  margin-top: -43.25px;
}

#selectionTabs {
  width: calc(100vw - 250px);
  position: relative;
  text-align: center;
  margin-top: 20px;
}

.selectionTabActive {
  padding: 5px 15px;
  background-color: $yellow;
  color: white;
  border: solid 1px $yellow;
  border-radius: 100px;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
}

.selectionTabInactive {
  padding: 5px 15px;
  background-color: #ffffff;
  color: $yellow;
  border: solid 1px #e9e9e9;
  border-radius: 100px;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
}

.selectionTabInactive:hover {
  border: solid 2px $yellow;
  margin: 9px;
}

.filter {
  display: block;
  font-size: 15px;
  margin-top: 10px;
  cursor: pointer;
}

#categoriesSelection {
  display: block;
  text-align: left;
  margin-left: 10px;
}

.popup {
  position: absolute;
  z-index: 30;
  right: 20px;
  top: 80px;
  display: block;
  background: $grey;
  color: white;
  padding: 20px;
  border-radius: 10px;

  a {
    float: right;
    margin-top: -60px;
    padding: 10px;
    cursor: pointer;
    font-size: 15px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

#page {
  #html-spinner {
    margin: auto;
  }
}

#uploadForm {
  display: block;
}

#form {
  margin: auto;
  display: block;
  flex-direction: column;
  justify-content: space between;
  height: 700px;
  width: 400px;
  margin: auto;
  padding: 0px;
  color: $grey;
  text-align: center;
  color: $charcoal;

  input,
  select {
    padding-left: 5px;
    font-family: "Ubuntu", sans-serif;
  }

  .in {
    width: 300px;
    border-radius: 5px;
    border: solid 1px $charcoal;
    padding: 12px;
  }

  textArea {
    height: 120px;
    font-family: "Ubuntu", sans-serif;
  }

  #fileType {
    width: 60px;
  }

  i {
    float: right;
    margin-right: 20px;
  }
}

#followingList {
  text-align: left;
}

#html-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid $light_yellow;
  border-top: 4px solid $yellow;
  border-radius: 50%;
}

#html-spinner {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1.2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#results {
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 60px;
  width: calc(80vw - 480px);
  height: calc(100vh - 80px);
  background: $light_yellow;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
  border-radius: 0px 0px 10px 10px;

  #closeSearchBtn {
    display: none;
  }
}

#results::-webkit-scrollbar {
  width: 5px;
  background-color: none;
}

#results::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $yellow;
}

button#followBtn {
  position: absolute;
  top: 55px;
  right: 5px;
  width: 120px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(to right, #efc056, #e27954);
}

#navOptions {
  padding: 10px;
  text-align: left;

  .navOption {
    margin-bottom: 10px;
    color: $light_yellow;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
    color: white;
    cursor: pointer;

    i {
      margin-right: 5px;
      color: $light_yellow;
      font-size: 18px;
      width: 28px;
    }
  }
}

#legal {
  padding: 10px;

  i {
    white-space: normal;
    width: 230px;
  }
}

.adsbygoogle {
  margin: 40px;
  height: 270px;
}

.commentGoogleAd {
  margin: 5px;
  height: 80px;
}

.consent {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #030303;
  text-align: left;
  color: white;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
}

.consent a {
  color: $yellow;
}

#closeCookies {
  border: none;
  color: white;
  padding: 20px;
  margin: 3px 20px 0px 15px;
  border-radius: 100px;
  background: #333;
  cursor: pointer;
  width: 10px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  float: right;
}

#hackathonNotice, #addPassword {
  position: fixed;
  z-index: 100;
  height: 450px;
  width: 320px;
  top: calc(50vh - 225px);
  left: calc(50% - 160px);
  padding: 20px;
  box-sizing: border-box;
  word-break: break-word;
  background: white;
  border-radius: 5px;
  line-height: 1.5;
}

#hackathonNotice a {
  text-decoration: underline;
  cursor: pointer;
}

#closeHackathonNotice {
  margin-top: 20px;
  width: 100%;
  background: linear-gradient(to right, #efc056, #e27954);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

#noticeTint {
  background: black;
  opacity: 0.5;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
}

// Mobile Styling
@media only screen and (max-width: 850px) {
  #nav {
    // display: none;
    width: 0px;
    overflow: hidden;
    transition: 0.5s;
    white-space: nowrap;
    z-index: 10;
  }

  #mobileTab {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: 100px;
    width: 40px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    color: white;
    background-color: $grey;
    z-index: 10;
    transition: 0.5s;

    i {
      margin-left: 3px;
    }
  }

  .mobileTabOpen {
    left: 250px;
  }

  .mobileTabClosed {
    left: 0px;
  }

  #bar {
    width: 100vw;
    height: 50px;
  }

  #translate {
    position: absolute;
    top: 80px;
    right: 20px;
    background: white;
    color: $charcoal;
    border: solid 1px #e9e9e9;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    z-index: 10;

    a {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }

    a:hover {
      background-color: #e9e9e9;
      border-radius: 100px;
    }

    img {
      width: 30px;
      height: 22.5px;
      box-shadow: 0px 0px 5px 0px $charcoal;
      margin-right: 10px;
    }
  }

  #app {
    width: 100vw;
    height: calc(100vh - 50px);
    top: 50px;
    overflow-x: hidden;
  }

  #page,
  #uploadPage {
    height: 100vh;
    width: 100vw;
    top: 0px;

    form {
      margin: auto;
    }
  }

  #selectionTabs {
    overflow-x: scroll;
    width: 100vw;
    margin: 0px;
    height: 35px;
    padding-top: 20px;
  }

  #register {
    margin-right: 0px;
    margin-top: -45px;
  }

  #login {
    margin-right: 55px;
    margin-top: -45px;
  }

  .userLoginIcon {
    padding: 10px;
  }

  #searchBar {
    width: calc(100% - 100px);
    min-width: 0px;
    margin: 10px;
    margin-left: 15px;
    margin-top: 7px;
  }

  #openTranslate {
    position: absolute;
    top: 17.5px;
    right: 15px;
    color: $charcoal;
  }

  #searchInput {
    width: calc(100% - 5px);
    min-width: 0px;
    padding: 10px;
  }

  #openNav {
    position: absolute;
    padding: 15px;
    font-size: 17px;
  }

  .post {
    margin: 0px;
    margin-bottom: 25px;
    margin-top: 10px;
    width: 85vw;
  }

  .postThumbnail {
    width: 85vw;
  }

  .adsbygoogle {
    margin: 0px;
    width: 85vw;
    height: 150px;
    margin: 10px 0px;
  }

  #results {
    position: absolute;
    z-index: 30;
    left: 0px;
    top: 50px;
    width: 100%;
    height: calc(100vh - 50px);
    background: #ffffff;
    border-radius: 0px;
    padding-top: 15px;
    padding-bottom: 15px;

    #closeSearchBtn {
      display: block;
      position: fixed;
      bottom: 20px;
      right: 20px;
      text-align: center;
      padding: 12px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      font-size: 25px;
      border-radius: 100px;
      background-color: $light_yellow;
    }
  }
}