// colours
$yellow: #fbb44a;
$grey: #2b281f;
$light_yellow: #fbecab;
$charcoal: #36454f;

#profile {
  width: 500px;
  height: 200px;
  padding: 10px;
  border-radius: 15px;
  display: block;
  position: relative;
  justify-content: space-evenly;
  margin: 50px;
  background: #e9e9e9;
  color: $grey;
  box-shadow: 0px 15px 15px #888888;

  #avatar-span {
    position: absolute;
    top: 5px;
    left: 5px;
    padding-top: 10px;
    writing-mode: vertical-rl; 
    font-size: 0.8em;
    text-align: center;
  }

  #avatar {
    border-radius: 100px;
    cursor: pointer;
  }

  #profileName {
    position: absolute;
    display: inline;
    top: 85px;
    left: 5px;

    p1 {
      font-size: 25px;
      font-weight: bold;
    }
  }

  #profileHandle {
    position: absolute;
    top: 120px;
    left: 5px;
  }

  #profileStats {
    position: absolute;
    bottom: 10px;
    right: 0px;
    width: 500px;
    display: flex;
    justify-content: space-evenly;
  }
}


.followingName {
  padding-left: 10px;
  text-decoration: none;
  color: white;
  font-size: 15px;
}

#avatar {
  width: 75px;
  height: 75px;
}

#verified {
  width: 15px;
  height: 13px;
  padding-left: 5px;
}

#displayToggle {
  position: fixed;
  bottom: 10px;
  right: 0;
  width: calc(100% - 250px);
  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 2;

  span {
    width: 80px;
    text-align: center;
    box-shadow: 2px 5px 15px $charcoal;
  }
}

@media only screen and (max-width: 850px) {
  #profile {
    width: 90vw;
    margin: 5vw;
    height: 200px;
    border-radius: 15px;
    
    #avatar {
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 100px;
    }

    #profileName {
      position: absolute;
      display: inline;
      top: 85px;
      left: 5px;
      
      p1 {
        font-size: 25px;
        font-weight: bold;
      }
    }

    #profileHandle {
      position: absolute;
      top: 120px;
      left: 5px;
    }

    #profileStats {
      position: absolute;
      top: 145px;
      width: 90vw;
      display: flex;
      justify-content: space-evenly;
    }
  }

  #displayToggle {
    width: 100%;
  }
}