// colours
$yellow: #fbb44a;
$grey: #2b281f;
$light_yellow: #fbecab;
$charcoal: #36454f;

.course {
  width: calc(90vw - 250px);
  position: relative;
  margin: 20px 0px;
  min-height: 250px;
  border: solid 1px #e9e9e9;
  color: $charcoal;
  cursor: pointer;

  img {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 100vw;
    max-width: 300px;
  }

  h3 {
    position: absolute;
    top: 0px;
    left: 380px;
    max-height: 45px;
    overflow-y: auto;
  }

  .courseDescription {
    position: absolute;
    top: 50px;
    left: 380px;
    max-height: 100px;
    overflow-y: auto;
  }

  .courseLength {
    position: absolute;
    top: 150px;
    left: 380px;
  }

  .courseCreator {
    position: absolute;
    top: 200px;
    left: 380px;
    width: 270px;
    max-height: 70px;
    cursor: pointer;

    span {
      position: relative;
    }

    p {
      position: absolute;
      top: -5px;
      left: 55px;
      overflow-y: auto;
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: 100px;
      background-color: white;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
}

#newCourseBtn {
  position: fixed;
  display: flex;
  bottom: 40px;
  right: 40px;
  padding: 10px 15px;
  border-radius: 100px;
  border: none;
  background: $light_yellow;
  color: $charcoal;
  font-size: 14px;
  cursor: pointer;

  p {
    margin: auto;
    padding-right: 15px;
  }

  i {
    font-size: 25px;
  }
}

#newCourseBtn:hover {
  background: $yellow;
}

#createCourse {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: calc(100vw - 250px);
  height: 100vh;
  background: #ffffff;
  padding: 40px 10vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;

  input,
  textArea {
    width: 300px;
    border-radius: 5px;
    border: solid 1px $charcoal;
    padding: 12px;
  }

  textArea {
    height: 150px;
  }

  button {
    width: 100px;
    margin: auto;
    border-radius: 5px;
    border: none;
    background-color: $light_yellow;
    font-weight: bold;
    padding: 5px;
    cursor: pointer;
  }

  .fa-arrow-left {
    position: fixed;
    bottom: 40px;
    left: 290px;
    padding: 10px;
    margin: auto;
    background-color: $light_yellow;
    font-size: 25px;
    border-radius: 100px;
    cursor: pointer;
  }

  .fa-arrow-left:hover {
    background-color: $yellow;
  }

  .addToCourse {
    position: absolute;
    right: -10px;
    bottom: -10px;
    padding: 10px;
    font-size: 22px;
    background-color: rgb(251, 236, 171);
    border-radius: 100px;
  }

  .addToCourse:hover {
    background-color: $yellow;
  }

  #courseSuccessModal {
    position: fixed;
    z-index: 15;
    padding: 20px 50px;
    border-radius: 5px;
    right: calc((50vw - 125px) - 184px);
    top: calc(50vh - 68px);
    background-color: $grey;
    color: white;

    a {
      color: $yellow;
      text-decoration: underline;
    }
  }
}

#addingPostsToCourseDiv {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 310px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% - 120px);
  padding: 0px 20px;
  margin: 0px;

  .courseOwnPost {
    position: relative;
    color: $charcoal;
    padding-bottom: 10px;
    background: #ffffff;
    font-weight: normal;
    break-inside: avoid-column;
    text-align: center;
    width: 250px;
    box-shadow: 1px 1px 10px 1px;
    border-radius: 10px;
    margin: 40px;
    cursor: default;
    position: relative;

    img {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
    }

    button {
      position: absolute;
      bottom: -10px;
      left: -10px;
      padding: 10px 20px;
      font-size: 15px;
      background-color: #e9e9e9;
      border-radius: 100px;
      border: none;
    }

    button:hover {
      background-color: $yellow;
    }
  }
}

#courseUploads {
  background: #e9e9e9;
  width: 100%;
  // height: 1000px;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

#course {
  width: calc(100vw - 250px);
  padding: 40px;
  position: absolute;
  top: 0px;
  left: 250px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;

  #courseHeader {
    position: relative;
    width: calc(100% - 120px);
    height: 200px;
    background: $light_yellow;
    padding: 0px 20px;
    border-radius: 5px;

    h4 {
      padding-top: 10px;
    }

    p {
      max-height: 100px;
      overflow-y: auto;
    }

    #courseLength {
      position: absolute;
      bottom: 0px;
      left: 20px;
    }
  }

  #courseInteractions {
    position: relative;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: left;
    align-items: center;

    a {
      background-color: white;
      border: solid 1px #e9e9e9;
      border-radius: 100px;
      padding: 2.5px;
    }

    #purchaseCourse {
      font-size: 18px;
      padding: 0 20px;
      margin-right: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        margin-right: 10px;
      }
    }

    #courseCreator {
      display: flex;
      justify-content: left;
      width: 160px;
      max-height: 70px;
      border: none;
      cursor: pointer;

      p {
        margin: auto 10px;
      }

      img {
        height: 40px;
        width: 40px;
        border-radius: 100px;
        background-color: white;
      }
    }
  }

  #coursePosts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    // height: calc(100vh - 350px);
    width: calc(100% - 80px);
    // overflow-y: auto;
  }

  #coursePosts::-webkit-scrollbar {
    width: 5px;
    background-color: none;
  }

  #coursePosts::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #e9e9e9;
  }

  #addPostsToCourse {
    position: fixed;
    left: 280px;
    bottom: 30px;
    padding: 15px;
    font-size: 18px;
    background-color: $light_yellow;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    box-shadow: 3px 3px 10px $charcoal;
  }

  #addPostsToCourse:hover {
    background-color: $yellow;
  }
}

@media screen and (max-width: 850px) {
  .course {
    width: calc(100vw - 40px);
    position: relative;
    margin: 20px 0px;
    min-height: 1px;

    img {
      position: relative;
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      max-width: 810px;
    }

    h3 {
      position: relative;
      left: 20px;
      top: auto;
      width: calc(100% - 40px);
      padding-top: 10px;
    }

    .courseDescription {
      position: relative;
      left: 20px;
      top: auto;
      width: calc(100% - 40px);
    }

    .courseLength {
      position: relative;
      left: 20px;
      top: auto;
      padding-top: 15px;
    }

    .courseCreator {
      position: relative;
      left: 180px;
      top: -45px;
      padding-top: 15px;

      p {
        width: calc(100vw - 280px);
      }
    }
  }

  #course {
    width: calc(100vw - 40px);
    height: 100vh;
    left: 0px;
    padding: 20px;

    #courseHeader {
      width: calc(100% - 20px);
      height: 200px;
      padding: 0px 10px;
      border-radius: 5px;

      #courseCreator {
        width: 200px;
        max-height: 70px;

        p {
          margin: auto 10px;
        }

        img {
          height: 40px;
          width: 40px;
          border-radius: 100px;
          background-color: white;
        }
      }
    }

    #courseInteractions {
      justify-content: space-between;
    }

    #coursePosts {
      width: 100%;
    }

    #addPostsToCourse {
      left: 30px;
      bottom: 30px;
    }

    #addingPostsToCourseDiv {
      width: 100%;
      padding: 0px;
      padding-bottom: 80px;
      height: auto;
    }
  }

  #createCourse {
    width: 100vw;
    padding: 20px;
    padding-bottom: 80px;

    #courseSuccessModal {
      padding: 20px;
      width: 300px;
      right: calc(50vw - 170px);
    }

    .fa-arrow-left {
      left: 30px;
      bottom: 20px; 
    }
  }
}

@media screen and (max-width: 410px) {
  #course {
    #courseHeader {
      height: 200px;
    }

    #courseLength {
      margin-bottom: 15px;
    }
  }
}