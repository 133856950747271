// colours
$yellow: #fbb44a;
$grey: #2b281f;
$light_yellow: #fbecab;
$charcoal: #36454f;

#cinema {
  position: fixed;
  width: 800px;
  height: calc(80vh - 60px);
  max-height: 450px;
  bottom: 20%;
  right: calc(50vw - 525px);
  background-color: #f7f7f7;
  box-shadow: 1px 1px 10px 1px;
  z-index: 2;
  overflow: hidden;

  #Video {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  #payWebinarThumb {
    position: relative;
    max-height: 280px;
    max-width: 500px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
  }
  #webinarThumb {
    position: relative;
    max-height: 280px;
    max-width: 500px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
  }
  .fa-play-circle {
    font-size: 100px;
    color: black;
    z-index: 3;
    position: absolute;
    cursor: pointer;
  }
  #playBtnThumbBacking {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
  }
  #comments {
    position: absolute;
    padding: 5px;
    top: 0px;
    left: 500px;
    width: 290px;
    overflow-y: scroll;
  }
  #info {
    position: absolute;
    top: 290px;
    left: 0px;
    width: 500px;
    overflow: hidden;
    height: calc(80vh - 350px);

    span {
      position: relative;
      width: 100%;
      height: 100%;

      #creatorProfile {
        position: absolute;
        left: 230px;
        top: 100px;
        display: flex;
        justify-content: left;
        width: 270px;
        max-height: 70px;
        cursor: pointer;

        img {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 100px;
        }
      }
      #title {
        position: absolute;
        top: -5px;
        left: 5px;
        width: 300px;
        max-height: 35px;
      }
      #description {
        position: absolute;
        top: 65px;
        left: 5px;
        width: 300px;
        max-height: 40px;
        overflow-y: scroll;
      }
      #date {
        position: absolute;
        left: 5px;
        top: 100px;
        width: 90px;
      }
      #category {
        position: absolute;
        left: 110px;
        top: 100px;
        width: 100px;
      }
      #likes {
        position: absolute;
        left: 330px;
        top: 5px;
        width: 60px;
        height: 15px;
      }
      #dislikes {
        position: absolute;
        left: 400px;
        top: 5px;
        width: 60px;
        height: 15px;
      }
      #pay {
        position: absolute;
        left: 330px;
        top: 60px;
        width: 130px;
        height: 25px;
        background: $yellow;
        background-image: linear-gradient(to right, #efc056, #e27954);
        color: white;
        border: none;
        font-weight: bold;
        border-radius: 2px;
        cursor: pointer;
      }
    }
  }
}

#editWebinar {
  @extend #cinema;
  background-color: white;
  border-radius: 10px;
  color: $charcoal;
  width: 95vw;
  padding: 10px;
  box-sizing: border-box;
  width: 95vw;
  max-width: 400px;
  height: 550px;
  max-height: 550px;
  right: calc(50vw - 325px);

  h2 {
    width: calc(100% - 10px);
    text-align: center;
    margin-top: 15px;
  }

  .fa-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    text-align: center;
    padding-top: 7px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .fa-close:hover {
    background-color: #e9e9e9;
  }

  .input {
    width: calc(100% - 30px);
    padding: 10px 15px;
    border: none;
    border-radius: 100px;
    background-color: #e9e9e9;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }

  textArea {
    height: 200px;
    width: calc(100% - 30px);
    padding: 10px 15px;
    border: none;
    border-radius: 18px;
    background-color: #e9e9e9;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }

  #update {
    background-color: $light_yellow;
    border-radius: 100px;
    border: none;
    color: $charcoal;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 25px;
    cursor: pointer;
  }

  #update:hover {
    background-color: $yellow;
  }

  form {
    margin: auto;
  }
}

#webinarPage {
  width: calc(100vw - 250px);
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 250px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  #Video {
    position: relative;
    height: calc(100vh - 200px);
    width: calc(100vw - 250px);
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
  }

  #payWebinarThumb {
    background-size: cover;
    position: relative;
    max-height: calc(100vh - 200px);
    max-width: calc(100vw - 250px);
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
  }

  #webinarThumb {
    background-size: cover;
    position: relative;
    max-height: calc(100vh - 200px);
    max-width: calc(100vw - 250px);
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
  }

  .fa-play-circle {
    font-size: 100px;
    color: black;
    z-index: 3;
    position: absolute;
    cursor: pointer;
  }

  #playBtnThumbBacking {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
  }

  #dyntube_Player {
    height: 100%;
    width: 100%;
  }

  #comments {
    position: absolute;
    padding: 5px;
    top: calc(100vh - 200px);
    right: 0px;
    width: 400px;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    // mix-blend-mode: difference;
    color: $charcoal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: white;

    .commentText, .commentDiv, .interactiveDivCounts, ul, p {
      // mix-blend-mode: difference;
      // color: white;
      color: $charcoal;
    }

  }

  #info {
    position: absolute;
    top: calc(100vh - 200px);
    left: 0px;
    width: 1000px;
    height: 200px;

    span {
      position: relative;
      width: 100%;
      height: 100%;

      #creatorProfile {
        position: absolute;
        left: 10px;
        top: 110px;
        display: flex;
        justify-content: left;
        width: 270px;
        max-height: 70px;
        cursor: pointer;

        img {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 100px;
        }
      }
      #title {
        position: absolute;
        top: -5px;
        left: 5px;
        width: calc(100vw - 820px);
        max-height: 25px;
        overflow-y: auto;
      }
      #description {
        position: absolute;
        top: 40px;
        left: 5px;
        width: calc(100vw - 820px);
        max-height: 40px;
        overflow-y: scroll;
      }
      #date {
        position: absolute;
        left: 5px;
        top: 100px;
        width: 90px;
      }
      #category {
        position: absolute;
        left: 300px;
        top: 115px;
        width: 100px;
      }
      #likes {
        position: absolute;
        left: calc(100vw - 800px);
        top: 5px;
        width: 60px;
        height: 15px;
      }
      #dislikes {
        position: absolute;
        left: calc(100vw - 730px);
        top: 5px;
        width: 60px;
        height: 15px;
      }
      #pay {
        position: absolute;
        left: calc(100vw - 810px);
        top: 60px;
        width: 130px;
        height: 40px;
        background: $yellow;
        background-image: linear-gradient(to right, #efc056, #e27954);
        color: white;
        border: none;
        font-weight: bold;
        border-radius: 2px;
        cursor: pointer;
      }
    }
  }

  .input {
    width: 400px;
    padding: 10px 5px;
    border: solid black 1px;
    font-family: "Ubuntu", sans-serif;
  }
  textArea {
    width: 400px;
    height: 200px;
    padding: 10px 5px;
    border: solid black 1px;
    font-family: "Ubuntu", sans-serif;
  }
  form {
    margin: auto;
  }
  #postBackBtn {
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 15px;
    border-radius: 100px;
    background: #e9e9e9;
    color: $charcoal;
    font-size: 20px;
    z-index: 2;
  }
}

// Responsiveness for desktop cinema
@media only screen and (max-width: 1080px) {
  #cinema {
    width: 500px;
    right: calc(50vw - 375px);
    overflow-y: scroll;

    #comments {
      top: 450px;
      left: 0px;
      width: 500px;
    }
  }

  #cinema::-webkit-scrollbar {
    width: 3px;
    background-color: none;
  }
  #cinema::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $yellow;
  }

  #editWebinar {
    width: 95vw;
    max-width: 400px;
    right: calc(50vw - 325px);
  }
}
@media only screen and (min-width: 1081px) {
  #comments {
    height: calc(80vh - 70px);
  }

  #comments::-webkit-scrollbar {
    width: 5px;
    background-color: none;
  }
  #comments::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $light_yellow;
  }
}

#_cinema {
  @extend #cinema;
  background: none;
  box-shadow: none;
}

#commentsContainer {
  width: 100%;
  font-weight: bold;
}

#commentsContainer ul {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#commentsContainer ul li {
  list-style: none;
  border-right: 2px solid #accfe6;
  padding-inline: 0.35rem;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

#commentsContainer ul li:hover {
  transform: scale(1.03);
}

.interactiveDiv {
  border-bottom: 1px solid #accfe6;
  padding-bottom: -1rem;
  margin-bottom: 1rem;
}

.interactiveDivCounts {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.commentDiv {
  display: flex;
}

.commentName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-right: auto;
  font-weight: bold;
  font-size: 14px;
}

.commentText {
  display: block;
  text-align: left;
  padding: 15px;
  margin-top: -10px;
  margin-bottom: -1%;
  color: #36454f;
}

.commentDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-right: 10px;
  font-size: 12px;
}

.commentAvatar {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: row;
  margin: 10px;
  border-radius: 100px;
}

.colourYellow {
  color: $yellow;
}

.colourBlack {
  color: $grey;
}

@media screen and (min-width: 851px) and (max-width: 1079px) {
  #webinarPage {
    height: 100vh;
    overflow-y: auto;

    #info {
      width: 100vw;

      span {  
        #creatorProfile {
          left: 10px;
          top: 170px;
        }
        #title {
          top: 5px;
          left: 15px;
          width: calc(100vw - 30px);
          max-height: 25px;
          overflow-y: auto;
        }
        #description {
          top: 40px;
          left: 15px;
          width: calc(100vw - 30px);
          max-height: 40px;
          overflow-y: auto;
        }
        #date {
          display: none;
        }
        #category {
          display: none;
        }
        #likes {
          left: 210px;
          top: 110px;
        }
        #dislikes {
          left: 290px;
          top: 110px;
        }
        #pay {
          left: 15px;
          top: 120px;
          width: 150px;
          height: 30px;
          font-size: 15px;
        }
      }
    }

    #comments {
      top: 700px;
      width: calc(100vw - 250px);
      left: 0px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 850px) {
  #cinema {
    width: 100vw;
    height: 80vh;
    max-height: 80vh;
    bottom: 10vh;
    right: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    #webinarThumb {
      max-height: unset;
      max-width: unset;
      width: 100vw;
      height: calc(100vw * 0.5625);
    }
    
    #info {
      top: 250px;
      width: 100vw;
      height: 500px;

      span {
        #creatorProfile {
          left: 5px;
          top: 170px;
          width: calc(100vw - 10px);
        }
        #title {
          left: 5px;
          width: calc(100vw - 10px);
        }
        #description {
          width: calc(100vw - 10px);
        }
        #likes {
          left: 25px;
          top: 120px;
        }
        #dislikes {
          left: 100px;
          top: 120px;
        }
        #pay {
          left: 150px;
          top: 130px;
        }
        #cinemaAdvert {
          position: absolute;
          top: 225px;
        }
      }
    }

    #comments {
      position: absolute;
      padding: 5px;
      top: 530px;
      width: 100vw;
      height: auto; 
    }
  }

  #editWebinar {
    width: 95vw;
    max-width: 95vw;
    right: 2.5vw;
    height: 550px;
    top: calc(50vh - 275px);
  };

  #webinarPage {
    width: 100vw;
    left: 0px;
    overflow-y: auto;
  
    #Video {
      width: 100vw;
      height: calc(100vw * 0.5625);
    }

    #webinarThumb {
      max-height: unset;
      max-width: unset;
      width: 100vw;
      height: calc(100vw * 0.5625);
    }
  
    #comments {
      position: absolute;
      padding: 5px;
      top: calc(100vw * 0.5625 + 280px);
      width: 100vw;
      height: auto; 
    }
  
    #info {
      top: calc(100vw * 0.5625 + 20px);
      right: 0px;
      width: 100vw;
      height: 300px;
  
      span {  
        #creatorProfile {
          left: 10px;
          top: 170px;
        }
        #title {
          top: 5px;
          left: 5px;
          width: calc(100vw - 10px);
          max-height: 25px;
          overflow-y: auto;
        }
        #description {
          top: 40px;
          left: 5px;
          width: calc(100vw - 10px);
          max-height: 40px;
          overflow-y: auto;
        }
        #date {
          display: none;
        }
        #category {
          display: none;
        }
        #likes {
          left: 200px;
          top: 110px;
        }
        #dislikes {
          left: 280px;
          top: 110px;
        }
        #pay {
          left: 5px;
          top: 120px;
          width: 150px;
          height: 30px;
          font-size: 15px;
        }
      }
    }
  
    .input {
      width: 400px;
      padding: 10px 5px;
      border: solid black 1px;
      font-family: "Ubuntu", sans-serif;
    }
    textArea {
      width: 400px;
      height: 200px;
      padding: 10px 5px;
      border: solid black 1px;
      font-family: "Ubuntu", sans-serif;
    }
    form {
      margin: auto;
    }
    #postBackBtn {
      position: fixed;
      bottom: 10px;
      left: 10px;
      padding: 15px;
      border-radius: 100px;
      background: #e9e9e9;
      color: $charcoal;
      font-size: 20px;
    }
    .commentGoogleAd {
      position: absolute;
      top: 450px;
      left: 20px;
    }
  }
}